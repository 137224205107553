// The `cookieconsent` global variable might not exist in some cases, like
// within iframes of the HelloBB button in the test environment.
//
// In those situations, there's an error:
//
//     Uncaught ReferenceError: cookieconsent is not defined
//
// That's why `window.cookieconsent` is being checked before use.

export default {
    isEnabled: true, // Set this value based on whether Mixpanel is enabled
    //cookieconsent.cookieConsentObject.userConsent.acceptedLevels.tracking

    identify(userId) {
        if (!window.cookieconsent) {
            return;
        }

        if (cookieconsent.cookieConsentObject.userConsent.acceptedLevels.tracking) {
            console.log('Mixpanel identify', userId);
            mixpanel.identify(userId);
        }
    },

    track(event, properties) {
        if (!window.cookieconsent) {
            return;
        }

        if (cookieconsent.cookieConsentObject.userConsent.acceptedLevels.tracking) {
            console.log('Mixpanel track', event, properties);
            mixpanel.track(event, properties);
        }
    },

    people: {
        set(properties) {
            if (!window.cookieconsent) {
                return;
            }

            if (cookieconsent.cookieConsentObject.userConsent.acceptedLevels.tracking) {
                mixpanel.people.set(properties);
            }
        },

        set_once(properties) {
            if (!window.cookieconsent) {
                return;
            }

            if (cookieconsent.cookieConsentObject.userConsent.acceptedLevels.tracking) {
                mixpanel.people.set_once(properties);
            }
        },

        increment(property, value) {
            if (!window.cookieconsent) {
                return;
            }

            if (cookieconsent.cookieConsentObject.userConsent.acceptedLevels.tracking) {
                mixpanel.people.increment(property, value);
            }
        }
    },

    register(properties) {
        if (!window.cookieconsent) {
            return;
        }

        if (cookieconsent.cookieConsentObject.userConsent.acceptedLevels.tracking) {
            mixpanel.register(properties);
        }
    }

    // Add other Mixpanel methods as needed

};
