import Vue from 'vue'
import { confirmationMessage } from './getters';

export const setUserOnServerSide = (state,user) => {
    state.user = user
    const featureFlag = (user.featureFlags || []).find(featureFlag => featureFlag.code === "contributions");
    console.log("featureFlag", featureFlag)
    state.contributionsFlag = featureFlag ? featureFlag.isEnabled : false;
};

export const setAuthToken = (state,auth_token) => {
    state.auth_token = auth_token
}

export const setCollaboratorDataOnServerSide = (state, user) => {
    state.user = Object.assign({}, state.user, {
        partner: user.partner,
        invitationText: user.invitationText,
        invitationStatus: user.invitationStatus,
        collaboratorRefused: user.collaboratorRefused,
        contributionsRefused: user.contributionsRefused,
        isMainUser: user.isMainUser,
        partnerId: user.partnerId
    });
}

export const setUser = (state, user) => {
    state.user = user
    state.isAuthenticated = true
    state.auth_token = user.token
    if (typeof window !== 'undefined') {
        window.__INITIAL_IS_AUTHENTICATED__ = true;
    }
};

export const setContributionsFlag = (state, contributionsFlag) => {
    console.log('Updating contributions flag:', contributionsFlag);
    state.user.featureFlags = state.user.featureFlags.map(flag => {
        if (flag.code === 'contributions') {
            flag.isEnabled = contributionsFlag;
        }
        return flag;
    });
    console.log('Updated state:', state.user.featureFlags);
};

export const updateUserField = (state,payload) => {
    state.user[payload.field] = payload.value
};

export const updateListField = (state,payload) => {
    state.ownerList[payload.field] = payload.value
};

export const updateUser = (state, user) => {
    updateUserField(state,{field: 'name',value: user.name})
    updateUserField(state,{field: 'phone',value: user.phone})
    updateUserField(state,{field: 'paypal',value: user.paypal})
    updateUserField(state,{field: 'adress',value: user.adress})
};


export const setPartner = (state, newPartnerData) => {
    state.user.partner = { ...state.user.partner, ...newPartnerData };
};

export const setInvitationData = (state, invitationData) => {
    state.user.invitationText = invitationData.invitationText;
    state.user.invitationStatus = invitationData.invitationStatus;
};

export const refuseCollaborator = (state) => {
    state.user.collaboratorRefused = true;
};

export const refuseContributions = (state) => {
    state.user.contributionsRefused = true;
};

export const notRefuseCollaborator = (state) => {
    state.user.collaboratorRefused = false;
};

export const deleteCollaborator = (state) => {
    state.user = Object.assign({}, state.user, { partner: null });
};

export const deleteInvitation = (state) => {
    state.user = Object.assign({}, state.user, { invitationText: null, invitationStatus: 'pending' });
};


export const logout = (state) => {
    state.isAuthenticated = false;
    state.user = null
    state.auth_token = null;
    if (typeof window !== 'undefined') {
        window.__INITIAL_IS_AUTHENTICATED__ = false;
    }
};

export const setUserList = (state, userList) => {
    state.myList = [];
    state.savedProducts = [];
    state.myList = userList;
    for (let i = 0; i < userList.products.length; i++) {
        state.savedProducts.push(userList.products[i].listProductId);
    }
};

export const setCategories = (state, categories) => {
    state.categories = categories;
};

export const setCollections = (state, collections) => {
    state.collections = collections;
};

export const setBlogTags = (state, blogTags) => {
    state.blogTags = blogTags;
};

export const setCategoriesPrefetched = (state, categoriesPrefetched) => {
    state.categoriesPrefetched = categoriesPrefetched;
};

export const setCollectionsPrefetched = (state, collectionsPrefetched) => {
    state.collectionsPrefetched = collectionsPrefetched;
};

export const setBlogTagsPrefetched = (state, blogTagsPrefetched) => {
    state.blogTagsPrefetched = blogTagsPrefetched;
};

export const setMyList = (state, myList) => {
    setUserList(state, myList)
};

export const setOwnerList = (state, ownerList) => {
    state.ownerList = ownerList;
};

export const setOwnerListPhoto = (state, photo) => {
    state.ownerList.photo = photo;
};

export const setOwnerListFunds = (state, funds) => {
    state.ownerList.funds = funds;
};

export const updateListTitle = (state, title) => {
    state.myList.title = title;
};

export const updateOwnerListTitle = (state, title) => {
    state.ownerList.title = title;
};

export const setCanScroll = () => {
    // Deixar buit! És simplement una mutació que el scrollbrehaviour de vue router subscriu per esperar
    // la finalització d'operacions asíncrones
};

export const setProductsScroll = (state, products) => {
    state.products.push(...products);
};

export const setProductsConstructorScroll = (state, products) => {
    state.productsConstructor.push(...products);
};

export const setProducts = (state, products) => {
    state.products = products;
};
export const setProductsConstructor = (state, products) => {
    state.productsConstructor = products;
};
export const setLoadingProducts = (state, loadingProducts) => {
    state.loadingProducts = loadingProducts;
};

export const setProductsPrefetched = (state, productsPrefetched) => {
    state.productsPrefetched = productsPrefetched;
};
export const setCollectionProductsPrefetched = (state, collectionProductsPrefetched) => {
    state.collectionProductsPrefetched = collectionProductsPrefetched;
};
export const setProduct = (state, data) => {
    state.product = data;
};

export const setSelectedQuantity = (state, selectedQuantity) => {
    state.selectedQuantity = selectedQuantity;
};

export const setSelectedOptions = (state, selectedOptions) => {
    state.selectedOptions = selectedOptions;
};

export const setSelectedOptionsByIndex = (state, { vm, index, newOption }) => {
    // Per limitacions en la detecció de canvis en Vue2 això no funciona -> https://v2.vuejs.org/v2/guide/reactivity.html#For-Arrays
    // this.selectedOptions[option.name] = value;
    vm.$set(state.selectedOptions, index, newOption)
};

export const setProductAsBought = (state, product) => {
    console.log('setProductAsBought', product)
    product.status = 2;
    if(product.isFree) {
        if(state.myList) {
            console.log('free mylist')
            let index = state.myList.products.findIndex(myListProduct => myListProduct.id === product.id);
            console.log('index', index)
            state.myList.products[index] = product;
            state.listUpdated = false;
        }
        if (state.ownerList && state.ownerList.products) {
            let index2 = state.ownerList.products.findIndex(ownerListProduct => ownerListProduct.id === product.id);
            state.ownerList.products[index2] = product;
            state.listUpdated = false;
        }
    }
    else {
        if(state.myList) {
            let index = state.myList.products.findIndex(myListProduct => myListProduct.listProductId === product.listProductId);
            state.myList.products[index] = product;
            state.listUpdated = false;
        }
        else {
            let index2 = state.ownerList.products.findIndex(ownerListProduct => ownerListProduct.listProductId === product.listProductId);
            state.ownerList.products[index2] = product;
            state.listUpdated = false;
        }
    }
};

export const toggleSaveProduct = (state, product) => {
    product.status = 1;
    // OLD LIST -> TODO ELIMINAR
    if (state.myList.products) state.myList.products.push(product);
    else {
        state.myList.products = []
        state.myList.products.push(product);
    }
    // NEW OWNER LIST
    if (state.ownerList.products) state.ownerList.products.push(product);
    else {
        state.ownerList.products = []
        state.ownerList.products.push(product);
    }

    return;

    let index = state.savedProducts.findIndex(savedProducts => savedProducts === product.listProductId);
    if (index === -1) {
        state.savedProducts.push(product.listProductId);
        product.status = 1;
        // OLD LIST -> TODO ELIMINAR
        if (state.myList.products) state.myList.products.push(product);
        else {
            state.myList.products = []
            state.myList.products.push(product);
        }
        // NEW OWNER LIST
        if (state.ownerList.products) state.ownerList.products.push(product);
        else {
            state.ownerList.products = []
            state.ownerList.products.push(product);
        }
    } else {
        state.savedProducts.splice(index, 1);
    }
};

export const unToggleSaveProduct = (state, listProductId) => {
    let index = state.savedProducts.findIndex(savedProducts => savedProducts === listProductId);
    state.savedProducts.splice(index, 1);
};

export const saveSelectedCategory = (state, category) => {
    state.selectedCategory = category;
};

export const setOrderBy = (state, orderBy) => {
    state.orderBy = orderBy;
};

export const SET_STEP_AS_CHECKED = (state, savedStep) => {
    state.steps = state.steps.map(function (step) {
        if (step.number === savedStep) {
            step.checked = true
        }
        return step
    });
}

export const SET_CURRENT_STEP = (state, currentStep) => {
    state.step = currentStep
}

export const UPDATE_WELCOME_MESSAGE = (state, welcomeMessage) => {
    state.user.welcomeMessage = welcomeMessage
}

export const SET_SEARCHSTATE = (state, searchState) => {
    state.searchState = searchState
}

export const SET_ADD_TO_LIST_VISIBILITY = (state, addToListVisibility) => {
    state.addToListVisibility = addToListVisibility
}

export const SET_CTA_FIXED_VISIBILITY = (state, ctaFixedVisibility) => {
    state.ctaFixedVisibility = ctaFixedVisibility
}

export const SET_CTA_FIXED_VISIBILITY2 = (state, ctaFixedVisibility2) => {
    state.ctaFixedVisibility2 = ctaFixedVisibility2
}

export const setBrands = (state, brands) => {
    state.brands = brands
}

export const setTags = (state, tag) => {
    state.tag = tag
}

export const setProductFilters = (state, productFilters) => {
    state.productFilters = productFilters;
    // if (localStorage) localStorage.setItem('productFilters', JSON.stringify(productFilters))
}

export const setGuestProductFilters = (state, guestProductFilters) => {
    state.guestProductFilters = guestProductFilters;
}

export const setOwnerProductFilters = (state, ownerProductFilters) => {
    state.ownerProductFilters = ownerProductFilters;
}

export const setWaitingFilters = (state, waitingFilters) => {
    state.waitingFilters = waitingFilters;
}

export const setBreadcrumb = (state, breadcrumb) => {
    state.breadcrumb = breadcrumb;
}

// export const initializeStore = (state) => {
//     if(process.browser) {
//         if (localStorage) {
//             let productFilters
//             if (productFilters = localStorage.getItem('productFilters')) {
//                 state.productFilters = JSON.parse(productFilters)
//             }
//         }
//     }
// };

export const setTag = (state, tag) => {
    state.tag = tag;
};

export const setPost = (state, post) => {
    state.post = post;
};

export const setFeaturedPost = (state, featuredPost) => {
    state.featuredPost = featuredPost;
};

export const setFeatured2Posts = (state, featured2Posts) => {
    state.featured2Posts = featured2Posts;
};

export const setProductPosts = (state, productPosts) => {
    state.productPosts = productPosts;
};

export const setMaternityPosts = (state, maternityPosts) => {
    state.maternityPosts = maternityPosts;
};

export const setExampleListsPosts = (state, exampleListsPosts) => {
    state.exampleListsPosts = exampleListsPosts;
};

export const setCategoryPosts = (state, categoryPosts) => {
    state.categoryPosts = categoryPosts;
};

export const setPosts = (state, posts) => {
    state.posts = posts;
};

export const setAlertMessage = (state, alertMessage) => {
    state.alertMessage = alertMessage;
};

export const setShowAlert = (state, showAlert) => {
    state.showAlert = showAlert;
};

export const setShowAlertKey = (state, showAlertKey) => {
    state.showAlertKey = showAlertKey;
};

export const setAlertType = (state, alertType) => {
    state.alertType = alertType;
};

export const setShowRetry = (state, showRetry) => {
    state.showRetry = showRetry;
};

export const setAlertRetrying = (state, alertRetrying) => {
    state.alertRetrying = alertRetrying;
};

export const setOwnerListProduct = (state, ownerListProduct) => {
    state.ownerListProduct = ownerListProduct;
};

export const setParserError = (state, parserError) => {
    state.parserError = parserError;
};

export const setParserErrorUrl = (state, parserErrorUrl) => {
    state.parserErrorUrl = parserErrorUrl;
};

export const setInvitation = (state, invitation) => {
    state.invitation = invitation;
};

export const setListConstructor = (state, listConstructor) => {
    state.listConstructor = listConstructor;
};

export const setLimit = (state, limit) => {
    state.limit = limit;
};

export const setTemporalLimit = (state, temporalLimit) => {
    state.temporalLimit = temporalLimit;
};

export const setToggleCategory = (state, toggleCategory) => {
    state.toggleCategory = toggleCategory;
};

export const setCollection = (state, collection) => {
    state.collection = collection;
};

export const setCollectionProducts = (state, collectionProducts) => {
    state.collectionProducts = collectionProducts;
};

export const addOpenSection = (state, sectionNumber) => {
    if(state.openSections.indexOf(sectionNumber) === -1) {
        state.openSections.push(sectionNumber);
    }
};

export const removeOpenSection = (state, sectionNumber) => {
    const index = state.openSections.indexOf(sectionNumber);
    if (index !== -1) {
        state.openSections.splice(index, 1);
    }
};

export const setCart = (state, cart) => {
    state.cart =JSON.parse(JSON.stringify(cart))
};

export const setToggleCart = (state, toggleCart) => {
    state.toggleCart = toggleCart;
};

export const setToggleLanguageSelector = (state, toggleLanguageSelector) => {
    state.toggleLanguageSelector = toggleLanguageSelector;
};

export const setDatosPrimerPaso = (state, firstStepData) => {
    state.firstStepData = firstStepData;
};

export const setDatosSegundoPaso = (state, secondStepData) => {
    state.secondStepData = secondStepData;
};

export const setStripeData = (state, stripeData) => {
    state.user.stripeAccountId = stripeData.StripeAccountId;
    state.user.addressLine1 = stripeData.AddressLine1;
    state.user.country = stripeData.Country;
    state.user.postalCode = stripeData.PostalCode;
    state.user.city = stripeData.City;
    state.user.province = stripeData.Province;
};

export const setVerifiedStatus = (state, verifiedStatus) => {
    state.user.verifiedStatus = verifiedStatus;
};

export const setConfirmationMessage = (state, message) => {
    state.confirmationMessage = {
        message: message,
        showMessage: true,
        showMessageError: false
      }
};

export const setErrorMessage = (state, message) => {
    state.confirmationMessage = {
        message: message,
        showMessage: false,
        showMessageError: true
      }
};

export const toggleLandbot = (state, value) => {
    state.landbotVisible = value;
};

export const reloadLandbot = (state) =>{
    // Cambia un valor en el estado que el componente Landbot usará para decidir recargarse
    console.log('reloadLandbot');
    state.reloadLandbot = !state.reloadLandbot;
};

export const setAuthorData = (state, authorData) => {
    console.log(authorData)
    state.user.author = authorData
};

export const setConstructorQuery = (state, query) => {
    state.constructorQuery = query;
};

export const setBalance = (state, balance) => {
    state.balance = balance;
}

export const setGuestCountry = (state, country) => {
    state.guestCountry = country;
}

export const setCurrencies = (state, currencies) => {
    state.currencies = currencies;
};

export const setCurrenciesPrefetched = (state, currenciesPrefetched) => {
    state.currenciesPrefetched = currenciesPrefetched;
};

export const setContributionsConfiguration = (state, contributionsConfiguration) => {
    state.user.contributionsConfiguration = contributionsConfiguration;
};

export const setExternalAccount = (state, externalAccount) => {
    state.externalAccount = externalAccount;
};

export const setListActive = (state, listActive) => {
    state.listActive = listActive;
};

export const setIsButtonUser = (state, isButtonUser) => {
    state.isButtonUser = isButtonUser;
};

// Workaround to temporarily update the value of an HTTP-only cookie.
export const setLanguageWorkaround = (state, language) => {
    if (!state.cookies) {
        state.cookies = {};
    }

    state.cookies.language = language;
};
