import axios from "axios";

// This is a normal form submission (not AJAX).
//
// It will redirect the browser to a page that returns the `Clear-Site-Data`
// header, and then the user will be redirected back to the home page.
//
// For more information, see:
//
//     https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Clear-Site-Data
function clearSiteData() {
  const form = document.createElement("form");
  form.method = "POST";
  form.action = "/clear-site-data";

  document.body.appendChild(form);

  form.submit();
}

export default {
  methods: {
    async logout(redirect = true) {
      let response = await axios.post("/logout");
      if (parseInt(response.status) === 200) {
        this.$store.commit("logout", "");
        this.$store.commit("setOwnerList", {
          funds: [],
          products: [],
        });

        this.$store.commit("setCart", null);

        // TODO
        this.$store.commit("setMyList", {
          funds: [],
          products: [],
        });

        this.$store.commit("setListActive", false);

        if (redirect) {
          //await this.$router.replace({ name: "home" });
          return clearSiteData();
        }
      } else {
        const errorMessage = "Incorrect logout!";
        console.log(errorMessage);
        throw new Error(errorMessage);
      }
    },
  },
};
