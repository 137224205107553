import { removeDoubleSlashes } from './helpers'
import { formatUrlhelloBB } from './string'
import { PREFIXES_BY_COUNTRY } from '../constants/phone'

const DEFAULT_CURRENCY = {
  symbol: "€",
};

function isStringWithContent(value) {
  return typeof value === "string" && value.trim() !== "";
}

function sanitizeCurrencyObject(currency) {
  if (typeof currency !== "object" || !currency) {
    console.warn(`Currency ${JSON.stringify(currency)} is not valid. Falling back to default currency.`);

    return DEFAULT_CURRENCY;
  }

  if (!isStringWithContent(currency.symbol)) {
    console.warn(`Currency symbol ${JSON.stringify(currency.symbol)} is not valid. Falling back to default currency.`);

    return DEFAULT_CURRENCY;
  }

  //console.log(`Using currency: ${JSON.stringify(currency)}`);

  return currency;
}

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

function updateQueryFilters(newFilters, hash = null) {
  if (newFilters.length > 0) {
    this.$router.push({ query: { ...this.$route.query, filters: JSON.stringify(newFilters) }, hash })
    // PROVOCA BUCLE INFINIT!
    // this.$router.push({ query: {...this.$route.query, filters: JSON.stringify(newFilters), t: new Date().getTime(),}})
  } else {
    let query = { ...this.$route.query }
    delete query.filters
    this.$router.push({ query: query, hash })
  }
}

function setProductFilters(newFilters) {
  this.$store.commit('setProductFilters', newFilters)
  updateQueryFilters.call(this, newFilters);
}

function setGuestProductFilters(newFilters, updateQueryString = true, hash = true) {
  this.$store.commit('setGuestProductFilters', newFilters)
  if (updateQueryString) updateQueryFilters.call(this, newFilters, hash ? 'price_range_filter' : null);
}

function setOwnerProductFilters(newFilters, updateQueryString = true, hash = null) {
  this.$store.commit('setOwnerProductFilters', newFilters)
  if (updateQueryString) updateQueryFilters.call(this, newFilters, hash);
}

async function sleep(fn, time, ...args) {
  await timeout(time)
  return fn(...args)
}

function optional(obj) {
  return new Proxy(obj, {
    get: function (target, name) {
      const result = target[name];
      if (result) {
        return (result instanceof Object) ? optional(result) : result;
      }
      return optional({});
    }
  });
}

function separateThousands(value, separator = ".") {
  if (typeof value !== "string") {
    throw new Error("`value` must be string.");
  }

  return value.replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1" + separator
  )
}

function formatNumber(num) {
  // Assume the decimal separator is always a comma.
  const decimalSeparator = ",";

  num = parseFloat(num);

  return separateThousands(
    num
      .toFixed(2) // always two decimal digits
      .replace(".", decimalSeparator)
  );
}

function formatMoney(num, currency = null) {
  currency = sanitizeCurrencyObject(currency);

  return formatNumber(num) + ` ${currency.symbol}`;
}

function formatMoneyNoSpace(num, currency = null) {
  currency = sanitizeCurrencyObject(currency);

  return formatNumber(num) + `${currency.symbol}`;
}

function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

function userAgent(platform) {
  return navigator.userAgent?.toLowerCase().indexOf(platform) > -1
}

function getPlatform() {
  if (userAgent("android")) return "Android";
  else if (userAgent("iphone") || userAgent("ipad")) return "iOS";
  return "Desktop";
}

function getRetailer(url) {
  let domain = "";
  try {
    domain = (new URL(url)).hostname;
  }
  catch (_) {
    return "";
  }
  var arrCopy = domain.toString().split(".");
  var retailer = arrCopy.length > 2 ? arrCopy[1] : arrCopy[0];
  if (retailer == "awin1") {
    const params = new URLSearchParams((new URL(url)).search);
    let domainA = (new URL(params.get("ued"))).hostname;
    var arrCopyA = domainA.toString().split(".");
    retailer = arrCopyA.length > 2 ? arrCopyA[1] : arrCopyA[0];
  }
  return retailer;
}

function capitalize(string) {
  if (typeof string !== 'string') return '';
  if (string == null) return '';
  return string[0].toUpperCase() + string.slice(1);
}

function getUserImgUrl(photo) {
  if (photo != null && photo != "") {
    if (!photo.toLowerCase().startsWith("http")) {
      return process.env.URL_IMG_USER + photo + "#" + new Date().getTime();
    }
    return photo + "#" + new Date().getTime();
  } else {
    return "https://uploads-ssl.webflow.com/5e0e147115200759427c6138/5fb3f787e7f28846e1cf713c_abella%20dreta64.png";
  }
}

function getImgUrl(photo) {
  if (photo != null && photo != "" && (typeof photo !== "undefined")) {
    if (!photo.toLowerCase().startsWith("http")) {
      return process.env.URL_IMG + photo + "#" + new Date().getTime();
    }
    return photo + "#" + new Date().getTime();
  } else {
    return "https://uploads-ssl.webflow.com/5e0e147115200759427c6138/5fb3f787e7f28846e1cf713c_abella%20dreta64.png";
  }
}

function getLogoRetalier(name, retailer = null, product = null) {
  // TODO: Remove the incorrect key once we know which one is correct.
  const logoUrl =
    retailer?.LogoUrl ||
    retailer?.logoUrl ||
    product?.LogoUrl ||
    product?.logoUrl ||
    "";

  if (logoUrl) {
    return `https://images.hellobb.net/logos/${logoUrl}` + "?tr=w-24,h-24,cm-pad_resize";
  }

  if (name && name !== "") {
    if (isARetailer(name)) {
      return "https://www.hellobb.net/" + formatUrlhelloBB(name) + ".png";
    }
  }

  return 'https://www.hellobb.net/Generic_x2.jpg'
}

const retailers = {
  0: 'HelloBB',
  1: 'Bebitus',
  2: 'Amazon',
  5: 'El Corte Inglés',
  6: 'Ikea',
  243: 'HelloBB',
  61: 'BabyBjörn',
  22: 'Vertbaudet',
  117: 'Carrefour',
  16: 'Maisonsdumonde',
  29: 'Stokke',
  51: 'Tutete',
  53: 'BBlandia',
  69: 'Planeta Huerto',
  9: 'Infantdeco',
  276: "Oh My Baby"
}

const paymentMethods = {
  1: {
    id: 170,
    name: 'Paypal',
    icon: '/logo-ico-paypal.svg',
    icon_full: '/logo-ico-paypal-full.svg',
    fundPaymentTypeId: 1,
    info: 'paypal'
  },
  2: {
    id: 171,
    name: 'Bizum',
    icon: '/logo-ico-bizum.svg',
    icon_full: '/logo-ico-bizum-full.svg',
    fundPaymentTypeId: 2,
    info: 'bizum'
  },
}

function paymentMethodIdByName(name) {
  const found = Object.values(paymentMethods).find(paymentMethod => paymentMethod.name == name)
  if (found) return found.id
  return null
}

function isARetailer(name) {
  if (name == "" || !name) return false
  return Object.values(retailers).find(element => element.toLowerCase() === name.toLowerCase());
}

function truncate(string, limit) {
  if (!string) return ''
  return (string.length < limit) ? string : string.substring(0, limit - 3) + '...'
}

const fundIcons = {
  1: "/ico-fondos-hucha.svg",
  2: "/ico-fondos-solidario.svg",
  3: "/ico-fondos-panales.svg",
  4: "/ico-fondos-crema.svg",
};

const validateEmail = (email) => {
  email = email.toLowerCase();
  const parts = email.split("@");
  if (parts.length !== 2) {
    return false;
  }

  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

function formatDate(date) {
  const dateObj = new Date(date)
  return [
    padTo2Digits(dateObj.getDate()),
    padTo2Digits(dateObj.getMonth() + 1),
    dateObj.getFullYear()
  ].join('/');
}

function metaInfo(article, context) {
  const currentUrl = process.env.APP_URL + context.$route.path
  return {
    meta: [
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:title', content: article.title },
      { name: 'twitter:description', content: article.excerpt || article.description || article.title },
      { name: 'twitter:image', content: article.feature_image || article.image || article.photo },
      { name: 'twitter:url', content: currentUrl },
      { name: 'twitter:label1', content: 'Written by' },
      { name: 'twitter:data1', content: 'HelloBB' },
      { name: 'twitter:label2', content: 'HelloBB' },
      { name: 'twitter:data2', content: article.primary_tag?.name },


      { property: 'og:title', content: article.title },
      { property: 'og:site_name', content: 'HelloBB' },
      { property: 'og:type', content: 'article' },
      { property: 'og:image', content: article.feature_image || article.image || article.photo },

      // TODO ???
      // {property: 'og:image:width', content:  '1280'},
      // {property: 'og:image:height', content:  '775'},
      { property: 'og:description', content: article.excerpt || article.description },
      { property: 'og:url', content: currentUrl },
      { property: 'article:published_time', content: article.published_at },
      { property: 'article:modified_time', content: article.updated_at },
      { property: 'article:tag', content: article.primary_tag?.name },
      { property: 'article:publisher', content: 'https://www.facebook.com/holahelloBB' }
    ],
    script: [{
      type: 'application/ld+json',
      json: {
        "@context": "https://schema.org",
        "@type": "WebSite",
        "publisher": {
          "@type": "Organization",
          "name": "HelloBB Blog - Información para padres novatos",
          "url": "https://www.hellobb.net/",
          "logo": {
            "@type": "ImageObject",
            "url": "https://www.hellobb.net/favicon.png",
            "width": 48,
            "height": 48
          }
        },
        "url": currentUrl,
        "image": {
          "@type": "ImageObject",
          "url": article.feature_image || article.image,
          // "width": 7183,
          // "height": 2885
        },
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "https://www.hellobb.net/"
        },
        "description": "Guías, información sobre productos y trucos que harán tu camino a la maternidad más fácil."
      }
    }]
  }
}

function retailerFromUrl(url) {
  var ret = getRetailer(url);
  return ret.charAt(0).toUpperCase() + ret.slice(1);
}

const isValidUrl = (url) => {
  if (url === "") return true;
  try {
    new URL(url);
  } catch (e) {
    return false;
  }
  return true;
};

function serverSide() {
  return (typeof window === 'undefined')
}

function clientSide() {
  return (typeof window !== 'undefined')
}

function removeDuplicates(arr) {
  return arr.filter((item, index) => arr.indexOf(item) === index);
}

function isApp() {
  if (typeof document !== 'undefined') {
    const cookieValue = document.cookie
      .split('; ')
      .find((row) => row.startsWith('sourceApp'))
      ?.split('=')[1];
    return cookieValue === "true" ? true : false;
  }
  return false; // En el servidor, simplemente devolvemos false o el valor por defecto.
}

function appVersion() {
  const cookieValue = document.cookie
    .split('; ')
    .find((row) => row.startsWith('appVersion'))
    ?.split('=')[1];
  if (cookieValue != "true") return cookieValue
  return null
}

function isEmpty(object) {
  return object && Object.keys(object).length === 0 && Object.getPrototypeOf(object) === Object.prototype
}

function getUserFromCookies() {
  return {
    email: this.$store.state.cookies.email,
    name: this.$store.state.cookies.name,
    photo: this.$store.state.cookies.photo,
    phone: this.$store.state.cookies.phone,
    paypal: this.$store.state.cookies.paypal,
    adress: this.$store.state.cookies.adress,
    id: this.$store.state.cookies.id ? parseInt(this.$store.state.cookies.id) : null,
    userType: this.$store.state.cookies.userType,
    childBirthday: this.$store.state.cookies.childBirthday,
    childName: this.$store.state.cookies.childName,
    welcomeMessage: this.$store.state.cookies.welcomeMessage,
  }
}

function getAuthTokenFromCookies() {
  return this.$store.state.cookies.auth_token
}

// Funcions extretes de: https://ajayrc.medium.com/application-insights-using-azure-and-vuejs-error-handling-and-event-logging-in-front-end-to-40874d76f152
function logErrorsAndExceptions(location, error) {
  if (this.$appInsights && this.$appInsights.trackException && process.env.APP_INSIGHTS_KEY) {
    this.$appInsights.trackException({
      exception: new Error(location),
      properties: error
    });
  } else {
    console.error(location, ':', error)
  }
}

function logInfoAndDebug(location, message) {
  if (this.$appInsights && this.$appInsights.trackEvent && process.env.APP_INSIGHTS_KEY) {
    this.$appInsights.trackEvent({
      name: location,
      properties: message
    });
  } else { console.log(location, ':', message) }
}

function isObjectVoid(obj) {
  return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
}

// Necessary because the builtin `parseInt` is too lax.
//
// Example: `parseInt("123hello", 10) === 123` evaluates to `true`.
function strictParseInt(rawValue) {
  if (typeof rawValue !== "number" && typeof rawValue !== "string") {
    // Not a valid type.
    return null;
  }

  const value = parseInt(rawValue, 10);

  if (value.toString() !== rawValue.toString()) {
    // `rawValue` was not an integer.
    return null;
  }

  return value;
}

// Vuex doesn't track some changes, like for example when properties are added
// to an object.
//
// This function creates a copy of the current state, and replaces Vuex state
// with that copy.
//
// That should force it to read its structure and create watchers for any new
// properties, etc.
function forceRefreshState() {
  const state = {
    ...this.$store.state,
  };

  this.$store.replaceState(state);
}

function formatPhoneNumber(phoneNumber, country) {
  if (typeof phoneNumber !== "string") {
    throw new Error("`phoneNumber` must be string");
  }
  if (typeof country !== "string") {
    throw new Error("`country` must be string.");
  }

  const prefix = PREFIXES_BY_COUNTRY[country];

  // If we don't know what prefix to use for the given country, return the
  // phone number unchanged.
  if (!prefix) {
    return phoneNumber;
  }

  // If it seems like the phone number already has a prefix, return it
  // unchanged.
  if (phoneNumber.startsWith("+")) {
    // TODO: Do we need to check if the prefix was included as `00` instead of
    // `+`? (E.g. "0034" instead of "+34").
    return phoneNumber;
  }

  return `${prefix}${phoneNumber}`;
}



export default {
  install(Vue) {
    Vue.prototype.$setProductFilters = setProductFilters
    Vue.prototype.$setGuestProductFilters = setGuestProductFilters
    Vue.prototype.$setOwnerProductFilters = setOwnerProductFilters
    Vue.prototype.$getKeyByValue = getKeyByValue
    Vue.prototype.$timeout = timeout
    Vue.prototype.$sleep = sleep
    Vue.prototype.$getPlatform = getPlatform
    Vue.prototype.$getRetailer = getRetailer
    Vue.prototype.$optional = optional
    Vue.prototype.$getLogoRetalier = getLogoRetalier
    Vue.prototype.$getImgUrl = getImgUrl
    Vue.prototype.$getUserImgUrl = getUserImgUrl
    Vue.prototype.$fundIcons = fundIcons
    Vue.prototype.$paymentMethods = paymentMethods
    Vue.prototype.$paymentMethodIdByName = paymentMethodIdByName
    Vue.prototype.$serverSide = serverSide
    Vue.prototype.$clientSide = clientSide
    Vue.prototype.$getUserFromCookies = getUserFromCookies
    Vue.prototype.$logErrorsAndExceptions = logErrorsAndExceptions
    Vue.prototype.$logInfoAndDebug = logInfoAndDebug
    Vue.prototype.$forceRefreshState = forceRefreshState
    Vue.prototype.$utils = {
      isObjectVoid,
      removeDuplicates,
      removeDoubleSlashes,
      formatUrlhelloBB,
      formatDate,
      formatMoney,
      formatMoneyNoSpace,
      capitalize,
      validateEmail,
      truncate,
      metaInfo,
      retailerFromUrl,
      isValidUrl,
      isEmpty,
      isApp,
      appVersion,
      strictParseInt,
      formatPhoneNumber,
    }
  }
}
